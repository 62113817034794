import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useSetRecoilState } from "recoil"
import { callbackModalOpen } from "../../atoms"

export default function WhySection() {
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)
  const features = [
    "Приемлемые цены, соотносящиеся с ценами на биржах LME на момент заключения соглашения",
    "Продажа катализатора без участия клиента, из другого населенного пункта",
    "Чуткость и доброжелательность к каждому клиенту",
    "Точный химический и спектральный анализ состава катализатора",
    "Гарантия проведения анализа и выплата в день получения отработанного материала",
    "Прозрачность сделки",
  ]

  return (
    <section
      aria-labelledby="social-impact-heading"
      className="mx-auto"
      id="company"
    >
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <StaticImage
            src="../../../assets/images/why-section-bg.jpg"
            alt="Почему стоит обратиться в КаталикАвто"
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="relative bg-gray-900 bg-opacity-60 py-6 px-6 sm:px-12 md:py-24 lg:px-16">
          <div className="relative mx-auto flex max-w-7xl flex-col items-center text-center ">
            <h2
              id="social-impact-heading"
              className="text-center text-xl font-bold text-white sm:text-4xl"
            >
              <span className="block sm:inline">Почему стоит обратиться </span>
              <span className="block sm:inline">именно к нам?</span>
            </h2>
            <h3 className="pt-3 text-center text-base font-light text-white md:pt-6 md:text-2xl">
              Сегодня многие работают в этой рыночной нише, как различные
              компании, так частные предприниматели. Однако не каждая скупка
              катализаторов может предложить клиенту:
            </h3>

            <ul className="my-3 mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-2 sm:gap-y-16 md:my-12 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
              {features.map(feature => (
                <li
                  key={feature}
                  className="border-t border-orange-500 pt-2 text-sm font-light text-white md:text-xl"
                >
                  {feature}
                </li>
              ))}
            </ul>

            <button
              onClick={() => setCallbackModalOpen(true)}
              className="flex items-center justify-center rounded-full border-0 bg-corp-orange py-4 px-4 text-base font-bold text-white
              hover:bg-orange-500  hover:text-gray-100 sm:py-4 sm:px-10 sm:text-2xl"
            >
              Оставить заявку
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
