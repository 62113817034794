import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WhySection from "../components/Home/WhySection"

const AboutPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Почему стоит обратиться именно к нам?и"
          description="Почему стоит обратиться именно к КаталикАвто"
        />
      }
      <div className="mx-auto overflow-hidden bg-white pt-20 text-lg text-gray-900 lg:pt-24">
        <WhySection />
      </div>
    </Layout>
  )
}

export default AboutPage
